import { lpAjaxParseJsonOld } from './utils.js';

const openPopupRazorpay = () => {
	const idPageCheckout = 'learn-press-checkout';

	const elPageCheckout = document.getElementById( idPageCheckout );
	const elCheckoutForm = document.getElementById( 'learn-press-checkout-form' );

	const button = document.querySelector(
		'#learn-press-checkout-place-order'
	);

	if ( ! button ) {
		return;
	}

	button.addEventListener( 'click', ( e ) => {
		if (
			document.querySelector(
				'#checkout-payment input.gateway-input:checked'
			).value === 'razorpay'
		) {
			// submit( button );
			if ( ! elCheckoutForm ) {
				console.error( 'Form checkout is not defined' );
				return;
			}
			if ( ! elPageCheckout ) {
				console.error( 'Page checkout is not defined' );
				return;
			}
			const formData = new FormData( elCheckoutForm );
			e.preventDefault();
			button.classList.add( 'loading' );
			const urlHandle = new URL( lpCheckoutSettings.ajaxurl );
			urlHandle.searchParams.set( 'lp-ajax', 'checkout' );
			fetch( urlHandle, {
				method: 'POST',
				body: formData,
			} )
				.then( ( res ) => res.text() )
				.then( ( data ) => {
					data = lpAjaxParseJsonOld( data );
					// console.log(data);
					if ( data.result === 'processing' ) {
						const RazorpayHandle = new Razorpay( data.options );
						RazorpayHandle.open();
						const elemPopup = document.querySelector(
							'.razorpay-container iframe'
						);
						if ( elemPopup != null ) {
							elemPopup.style.maxHeight = '640px';
						}
					} else {
						const elemError = document.querySelector(
							'.learn-press-message.error'
						);
						if ( elemError != null ) {
							elemError.remove();
						}
						elCheckoutForm.insertAdjacentHTML(
							'afterbegin',
							`<div class="learn-press-message error">${ data.messages }</div>`
						);
					}
					button.classList.remove( 'loading' );
				} )
				.finally( () => {} )
				.catch( ( err ) => console.log( err ) );
		}
	} );
};

document.addEventListener( 'DOMContentLoaded', function() {
	openPopupRazorpay();
} );
